//
//
//
//
//
//

export default {
    name: 'ElUdimiLink',
    props: {
        to: {
            type: [String, Object],
            default: () => ''
        },
        regularLink: {
            type: Boolean,
            default: () => false
        }
    },
    computed: {
        is () {
            return this.regularLink ? 'a' : 'nuxt-link'
        },
        linkProps () {
            const props = { ...this.$props }
            delete props.regularLink
            if (this.regularLink) {
                props.href = props.to
                if (this.$config.siteHost === 'out.udimi.me' && props.href.startsWith('/')) {
                    props.href = `https://udimi.com${props.href}`
                }
                delete props.to
            }
            return props
        }
    }
}
