//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapGetters, mapState } from 'vuex'
import ProAchievements from '~/components/profile/ProCommon/ProAchievements'
import ProRepeatOrdersRate from '~/components/profile/ProCommon/ProRepeatOrdersRate'
import ProSalesPercent from '~/components/profile/ProCommon/ProSalesPercent'
import ProRating from '~/components/profile/ProCommon/ProRating'

export default {
    name: 'ProHeader',
    components: {
        ProAchievements,
        ProRepeatOrdersRate,
        ProSalesPercent,
        ProRating
    },
    props: {
        userInfo: {
            type: Object,
            required: true
        },
        isRecommends: {
            type: Boolean,
            default: false
        },
        isSolodeal: {
            type: Boolean,
            default: false
        },
        post: {
            type: Object,
            default: () => ({})
        }
    },
    computed: {
        ...mapState({
            timeDiff: state => state.app.timeDiff || 0
        }),
        ...mapGetters({
            isMobile: 'isMobile'
        }),
        memberForDta () {
            return this.getDtaDiffWithNow(this.userInfo?.user?.dtaReg)
        },
        lastSeenDta () {
            return this.getDtaDiffWithNow(this.userInfo?.user?.dtaLastSeen, true)
        },
        currentTime () {
            const now = this.$dayjsUTC().valueOf() + this.timeDiff
            return new Intl.DateTimeFormat('en-GB', {
                hour: 'numeric',
                minute: 'numeric',
                timeZone: this.userInfo.user.customTimezone
            }).format(now)
        },
        isNight () {
            const hours = Number(this.currentTime.split(':')?.[1]) || 0
            return hours < 8 || hours >= 21
        },
        salesPercent () {
            return this.userInfo.salesPercent?.value ? `${this.userInfo.salesPercent?.value}%` : 'n/a'
        },
        repeatOrdersRate () {
            return this.userInfo.repeatOrdersRate?.value ? `${this.userInfo.repeatOrdersRate?.value}%` : 'n/a'
        },
        avatarAlt () {
            return `${this.userInfo?.user?.fullname} solo ad seller`
        }
    },
    methods: {
        getDtaDiffWithNow (dta, negative = false) {
            const now = this.$dayjs().valueOf() + this.timeDiff
            const duration = this.$dayjs.duration(this.$dayjs(dta).valueOf() - now)

            return { abs: this.$dayjs(dta).format('DD MMM YYYY, HH:mm'), duration: duration.humanize(negative) }
        }
    }
}
