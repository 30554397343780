//
//
//
//
//
//
//
//
//
//

export default {
    name: 'ProRepeatOrdersRate',
    props: {
        repeatOrdersRate: {
            type: Object,
            default: () => ({})
        }
    },
    computed: {
        value () {
            return this.repeatOrdersRate?.value ? `${this.repeatOrdersRate?.value}%` : 'n/a'
        },
        tooltip () {
            return this.repeatOrdersRate?.value
                ? `<div class="b-large-hint">
                    <p>Percent of repeat customers.</p>
                    <p>
                        * Calculated for latest unique buyers which have placed orders within the last year <br />
                        * Only 2 repeated orders per buyer are taken into calculation
                    <p>
                        Example: 20% means that from 100 latest buyers, 20 buyers made more than one order.
                    </p>
                </div>`
                : `<div class="b-large-hint"><p>Percent of repeat customers.</p>
                    <p>There is not enough information. You need at least ${this.repeatOrdersRate?.repeatableOrdersEmptyLimit} unique buyers which have placed orders within the last year.</p>
                </div>`
        }
    }
}
