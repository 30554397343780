//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
    name: 'ProAchievements',
    props: {
        achievements: {
            type: Object,
            default: () => ({})
        },
        hidePrime: {
            type: Boolean,
            default: false
        },
        hideReferred: {
            type: Boolean,
            default: false
        }
    },
    computed: {
        verifiedTooltip () {
            return this.achievements?.verified &&
                `<div class="c-verified-tooltip">
                    The identity of this person has been verified through ID or passport verification by the industry-leading provider, Veriff.
                     Please note that although the ID of this person has been verified, Udimi members are permitted to use pen names.
                </div>`
        }
    }
}
