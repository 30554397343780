//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
    name: 'ElInfoBox',
    props: {
        iconClass: {
            type: String,
            default: 'ud-info-1'
        },
        type: {
            type: String,
            default: 'info'
        }
    },
    computed: {
        hasRightActionSlot () {
            return !!this.$slots['button-action']
        }
    }
}
