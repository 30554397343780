//
//
//
//
//
//
//
//
//
//

export default {
    name: 'ProSalesPercent',
    props: {
        salesPercent: {
            type: Object,
            default: () => ({})
        }
    },
    computed: {
        value () {
            return this.salesPercent?.value ? `${this.salesPercent?.value}%` : 'n/a'
        },
        tooltip () {
            return this.salesPercent?.value
                ? `<div class="b-large-hint">
                    <p>Percent of ratings where buyers report sales. </p>
                    <p>
                        Included in calculation: <br />
                        * ${this.salesPercent?.gotSalesSolosLimit} latest "Got sales" vs. "Got no sales" ratings</p>
                    <p>
                        Excluded from calculation: <br />
                        * "N/A sales or free site" type of ratings <br />
                        * Orders without a rating at all
                    </p>
                    <p>
                        Example: <br />
                        If a seller sold 100 solos, got 80 "N/A sales" ratings, 10 "Got sales" ratings and 10 "No sales" ratings, the ratio will be 50% only.
                    </p>
                </div>`
                : `<div class="b-large-hint"><p>Percent of ratings where buyers report sales.</p>
                    <p>Needs at least ${this.salesPercent?.gotSalesSolosEmptyLimit} orders with "Got sales" or "No sales" ratings from buyers.</p>
                </div>`
        }
    }
}
