//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapGetters, mapState } from 'vuex'
import createUrl from '~/utils/mixins/createUrl'
import ProAchievements from '~/components/profile/ProCommon/ProAchievements'
import ProRepeatOrdersRate from '~/components/profile/ProCommon/ProRepeatOrdersRate'
import ProSalesPercent from '~/components/profile/ProCommon/ProSalesPercent'
import ProRating from '~/components/profile/ProCommon/ProRating'
import { mobileTooltipParams } from '~/utils/helpers'

export default {
    name: 'ProHeaderMobile',
    components: { ProAchievements, ProRepeatOrdersRate, ProSalesPercent, ProRating },
    mixins: [createUrl],
    props: {
        userInfo: {
            type: Object,
            default: () => ({})
        },
        isRecommends: {
            type: Boolean,
            default: false
        },
        isSolodealsPage: {
            type: Boolean,
            default: false
        },
        formSelector: {
            type: String,
            default: ''
        }
    },
    data: () => ({
        mobileTooltipParams,
        isShowBuyBtn: false
    }),
    computed: {
        ...mapGetters({
            checkAccess: 'user/checkAccess'
        }),
        ...mapState({
            timeDiff: state => state.app.timeDiff || 0
        }),
        currentTime () {
            const now = this.$dayjsUTC().valueOf() + this.timeDiff
            return new Intl.DateTimeFormat('en-GB', {
                hour: 'numeric',
                minute: 'numeric',
                timeZone: this.userInfo.user.customTimezone
            }).format(now)
        },
        isNight () {
            const hours = Number(this.currentTime.split(':')?.[1]) || 0
            return hours < 8 || hours >= 21
        },
        memberForDta () {
            return this.getDtaDiffWithNow(this.userInfo.user.dtaReg)
        },
        lastSeenDta () {
            return this.getDtaDiffWithNow(this.userInfo.user.dtaLastSeen, true)
        },
        avatarAlt () {
            return `${this.userInfo?.user?.fullname} solo ad seller`
        },
        isResponsiveSeller () {
            return this.userInfo?.responsiveness?.isResponsive
        },
        isAchievementsAvailable () {
            return process.server || !this.isShowBuyBtn ||
                (this.$refs.profileNameEl?.offsetWidth < (this.$refs.profileHeaderEl?.offsetWidth - (this.$refs.profileAchievements?.$el?.offsetWidth || 0) - 145)) // 145 - all buttons width + margins
        }
    },
    mounted () {
        const soloFormElement = this.formSelector && document.querySelector(this.formSelector)
        if (soloFormElement) {
            this.setObserver(soloFormElement)
        }
    },
    destroyed () {
        this.observer?.disconnect()
    },
    methods: {
        getDtaDiffWithNow (dta, negative = false) {
            const now = this.$dayjs().valueOf() + this.timeDiff
            const duration = this.$dayjs.duration(this.$dayjs(dta).valueOf() - now)

            return { abs: this.$dayjs(dta).format('DD MMM YYYY, HH:mm'), duration: duration.humanize(negative) }
        },
        showAlert () {
            this.$alert('To use this feature, please login or create free account at Udimi')
        },
        scrollToForm () {
            const soloFormElement = this.formSelector && document.querySelector(this.formSelector)
            if (soloFormElement) {
                this.$scrollTo(soloFormElement, 250, { offset: -66 }) // 60 - $header-height; 6 - custom offset
            }
        },
        goBack () {
            this.$router.back()
        },
        setObserver (soloFormElement) {
            this.observer = new IntersectionObserver((entries) => {
                entries.forEach((entry) => {
                    this.isShowBuyBtn = entry.intersectionRatio === 0 && entry.boundingClientRect.top <= 0
                })
            }, { root: document, rootMargin: '-54px 0px 0px 0px' }) // 54px - header-height
            this.observer.observe(soloFormElement)
        }
    }
}
