//
//
//
//
//
//
//
//
//
//
//
//

import { formatUserRating } from '~/utils/helpers'

export default {
    name: 'ProRating',
    props: {
        uidProfile: {
            type: String,
            default: null
        },
        isSeller: {
            type: Boolean,
            default: false
        },
        isDashboard: {
            type: Boolean,
            default: false
        },
        ratings: {
            type: Object,
            default: () => ({})
        }
    },
    computed: {
        ratingSummary () {
            return this.isSeller
                ? { stars: +this.ratings.seller.starsRateAvg, all: this.ratings.seller.all }
                : { stars: +this.ratings.buyer.starsRateAvg, all: this.ratings.buyer.all }
        },
        ratingSummaryInvert () {
            return !this.isSeller
                ? { stars: +this.ratings.seller.starsRateAvg, all: this.ratings.seller.all }
                : { stars: +this.ratings.buyer.starsRateAvg, all: this.ratings.buyer.all }
        },
        tooltip () {
            return this.isDashboard
                ? this.isSeller ? 'Verified ratings from buyers' : 'Ratings from sellers'
                : (this.isSeller ? 'Verified ratings from buyers of this person.' : 'Ratings from sellers for this person.') + ' Click to see all ratings'
        }
    },
    methods: {
        formatUserRating
    }
}
